<template>
  <div class="content">
    <Spinner :isLoading="isLoading" />
    <div>
      <b-container fluid class="mt--7">
        <b-row>
          <b-col xl="12">
            <card>
              <b-row>
                <b-col xl="12" lg="12" md="12" class="p-1">
                  <h3 class="font-weight-bolder">Upcoming Renewals</h3>

                  <div class="mt--6 px-2 px-sm-4">
                    <div>
                      <div class="bg-white rounded-bottom change-color">
                        <div>

                          <el-table style="width: 98%; margin-top: 100px;" :data="renewalData" row-key="_id"
                            :default-sort="{
                              prop: 'Ubind_quote_created_date',
                              order: 'descending'
                            }" header-row-class-name="thead-light" v-loading="loading" :row-key="getRowKeys">
                            <!-- <el-table-column type="expand">
                            <template #default="props">
                              <b-row class="px-5 expandedPolicy">
                                <b-col cols="6" md="9">
                                  <b-row>
                                    <b-col cols="4">
                                      <p>Contact Name:<base-button @click.native="viewCustomerDetails(props.row)"
                                          class="edit" type="danger" size="sm" style="margin-left: 10px;padding:5px"
                                          v-b-tooltip.hover title="View Customer Details">
                                          View Customer Details
                                        </base-button></p>
                                      <p>Insured Email:</p>
                                      <p>Number Of Vehicles (Including Trailers):</p>
                                      <p>Business Description:</p>
                                      <p>Vehicle Use:</p>
                                      <p>Policy Purchased:</p>
                                      <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'adjustment'">
                                        Policy
                                        Adjusted:</p>
                                      <p v-if="props.row.Ubind_status === 'Cancelled'">Policy Cancelled:</p>
                                      <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'renewal'">
                                        Policy
                                        Renewal:</p>
                                    </b-col>
                                    <b-col cols="4">
                                      <p>
                                        {{ props.row.Customer_contact_first_name }}
                                        {{ props.row.Customer_contact_last_name }}

                                      </p>
                                      <p>{{ props.row.Customer_contact_email }}</p>
                                      <p>
                                        {{
                                          getNumberOfVehicles(props.row.Ubind_quote_details_obj)
                                        }}
                                      </p>
                                      <p>
                                        {{
                                          checkUbindFields(props.row.Ubind_quote_details_obj, "businessDescription")
                                        }}
                                      </p>
                                      <p>
                                        {{
                                          checkUbindFields(props.row.Ubind_quote_details_obj, "vehicleUsePurpose")
                                        }}
                                      </p>
                                      <p>
                                        The policy was issued effective on {{ props.row.effectiveDateTimeNewPolicy ?
                                          formatDatePolicy(props.row.effectiveDateTimeNewPolicy) :
                                          formatDatePolicy(props.row.Ubind_quote_last_updated_date) }}
                                      </p>
                                      <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'adjustment'">
                                        The policy was adjusted effective on {{
                                          formatDatePolicy(props.row.Ubind_quote_details_obj.adjustmentDateTime) }}
                                      </p>
                                      <p v-if="props.row.Ubind_status === 'Cancelled'">
                                        The policy was issued on {{
                                          formatDatePolicy(props.row.Ubind_quote_last_updated_date) }} {{
    props.row.Ubind_quote_details_obj.cancellationReason ? `- Reason:
                                        ${props.row.Ubind_quote_details_obj.cancellationReason}` : `` }}
                                      </p>
                                      <p v-if="props.row.Ubind_quote_details_obj.policyTransactionType === 'renewal'">
                                        The policy was renewed effective on {{
                                          formatPolicyEndDate(props.row.Ubind_quote_details_obj.formDataFormatted.policyStartDate)
                                        }}
                                      </p>
                                    </b-col>

                                  </b-row>
                                </b-col>
                                <b-col md="1"></b-col>

                                <b-col cols="6" md="2">
                                  <b-row class="pl-2" align-h="center">
                                    <b-col cols="auto" md="12" class="mb-4">
                                      <base-button v-if="props.row.Ubind_status != 'Cancelled'"
                                        @click.native="() => downloadCOC(props.row)" class="edit" type="danger" size="sm"
                                        icon v-b-tooltip.hover title="Download PDF">
                                        <i class="fas fa-file-download btn-download"><span> Cert of Currency</span></i>
                                      </base-button>
                                      <base-button v-else
                                        @click.native="() => downloadFileDoc(props.row, 'lc-credit-note', 'credit note.pdf')"
                                        class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                                        <i class="fas fa-file-download btn-download"><span> Credit Note</span></i>
                                      </base-button>
                                    </b-col>
                                    <b-col cols="auto" md="12" class="mb-4">
                                      <template v-if="props.row.Ubind_status != 'Cancelled'">
                                        <base-button @click.native="() => downloadPS(props.row)" class="edit"
                                          type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                                          <i class="fas fa-file-download btn-download"><span> Policy Schedule
                                              (PDF)</span></i>
                                        </base-button><br /><br />

                                        <base-button v-if="props.row.Customer_policy_id > 2859"
                                          @click.native="() => downloadPSDOC(props.row)" class="edit" type="danger"
                                          size="sm" icon v-b-tooltip.hover title="Download PDF">
                                          <i class="fas fa-file-download btn-download"><span> Policy Schedule
                                              (DOCX)</span></i>
                                        </base-button>

                                      </template>

                                      <base-button v-else
                                        @click.native="() => downloadFileDoc(props.row, 'lc-cancellation-notice', 'cancellation notice.pdf')"
                                        class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                                        <i class="fas fa-file-download btn-download"><span> Cancellation note</span></i>
                                      </base-button>
                                    </b-col>
                                    <b-col cols="auto" md="12">
                                      <base-button v-if="props.row.Ubind_status != 'Cancelled'"
                                        @click.native="() => downloadFileDoc(props.row, 'lc-tax-invoice', 'tax invoice.pdf')"
                                        class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download PDF">
                                        <i class="fas fa-file-download btn-download"><span> Tax Invoice</span></i>
                                      </base-button>
                                    </b-col>
                                    <b-col cols="auto" md="12">
                                      <base-button
                                        v-if="props.row.Ubind_status != 'Cancelled' && isDowloadFleetFile(props.row.Ubind_quote_details_obj)"
                                        @click.native="() => downloadFileDoc(props.row, 'lc-schedule-of-vehicles', 'schedule of vehicles.xlsx')"
                                        class="edit" type="danger" size="sm" icon v-b-tooltip.hover title="Download XLSX">
                                        <i class="fas fa-file-download btn-download"><span> Schedule of
                                            Vehicles</span></i>
                                      </base-button>
                                    </b-col>
                                  </b-row>
                                </b-col>
                              </b-row>
                            </template>
                          </el-table-column>
                          <el-table-column min-width="95px" align="center" label="VIEW DOCS & DETAILS">
                            <div slot-scope="{ row }" tabindex="0" v-b-tooltip.hover.righttop
                              title="View Docs and Details">
                              <base-button @click.native="expandRow(row)" class="edit" type="danger" size="sm" icon>
                                <b-icon font-scale="2" icon="files"></b-icon>
                              </base-button>
                            </div>
                          </el-table-column>
                          <el-table-column v-if="isDisplayAdjustButton" min-width="80px" align="left" label="Adjust">
                            <div slot-scope="{ row }">
                              <base-button @click.native="handlePolicyModals(row, 'adjustment')" class="edit"
                                :type="row.isDisableAdjust ? 'dark' : 'danger'" size="sm" icon
                                :disabled="row.isDisableAdjust" v-b-tooltip.hover.righttop title="Adjust Policy">
                                <b-icon font-scale="2" icon="pencil-square"></b-icon>
                              </base-button>
                            </div>
                          </el-table-column>
                          <el-table-column v-if="isDisplayCancelButton" min-width="80px" align="left" label="Cancel">
                            <div slot-scope="{ row }">
                              <base-button @click.native="handlePolicyModals(row, 'cancellation')" class="edit"
                                :type="row.isDisableCancel ? 'dark' : 'danger'" size="sm" icon
                                :disabled="row.isDisableCancel" v-b-tooltip.hover.righttop title="Cancel Policy">
                                <b-icon font-scale="2" icon="x"></b-icon>
                              </base-button>
                            </div>
                          </el-table-column>
                          -->
                            <el-table-column min-width="100px" align="left" label="Renew">
                              <div slot-scope="{ row }" v-b-tooltip.hover.righttop
                                title="Enabled within 45 days before the expiry date">
                                <base-button @click.native="handlePolicyModals(row, 'renew')" class="edit"
                                  :type="row.isDisableRenew ? 'dark' : 'danger'" size="sm" icon
                                  :disabled="row.isDisableRenew">
                                  <b-icon font-scale="2" icon="arrow-clockwise"></b-icon>
                                </base-button>
                              </div>
                            </el-table-column>

                            <el-table-column label="Type" min-width="130px" prop="type">
                            </el-table-column>

                            <el-table-column label="Status" min-width="130px" prop="Ubind_status">
                            </el-table-column>

                            <el-table-column label="Business Name" prop="Customer_insured_name" min-width="220px"
                              sortable>
                            </el-table-column>



                            <el-table-column label="Current Premium" prop="Customer_policy_insurance_total_payable"
                              min-width="140px" sortable :formatter="row => formatPremium(row)">
                            </el-table-column>

                            <el-table-column label="Policy Number" min-width="150px" prop="policy_number" sortable>
                            </el-table-column>

                            <el-table-column label="Expiry Date" min-width="150px" prop="policy_end_date" sortable
                              :formatter="row => formatDateWithSlash(row.policy_end_date)">
                            </el-table-column>

                            <!-- <el-table-column label="Last Updated" min-width="180px" prop="last_modified" sortable
                          :formatter="row => formatDateTime(row.last_modified)">
                        </el-table-column> -->

                            <el-table-column label="Broker Name" min-width="180px" prop="Broker_Name" sortable>
                            </el-table-column>

                            <el-table-column label="Broker Organization" min-width="180px" prop="Broker_Org_Name"
                              sortable>
                            </el-table-column>

                            <template #empty>
                              <p>{{ loading ? "" : "No upcoming policy renewals found." }}</p>
                            </template>
                          </el-table>
                        </div>
                      </div>

                    </div>
                  </div>




                </b-col>


              </b-row>
              <DialogConfirm v-on:modelPopupCancel="modelPopupCancel" :title="title" :titleDigLog="titleDigLog"
                v-on:setLoading="setLoading" v-on:handleCallAPI="handleCallAPI"
                :cancelConfirmModal="cancelConfirmModal" />

              <DialogResume v-on:modelPopupResume="modelPopupResume" :title="title" :message="message"
                v-on:setLoading="setLoading" v-on:handleCallAPI="handleCallAPI" :quoteCancel="quoteCancel"
                v-on:handleContinuePolicies="handleContinuePolicies" :centerDialogVisible="centerDialogVisible" />

              <DialogDiscard v-on:modelPopupDiscard="modelPopupDiscard" :title="title" :message="message"
                :quoteCancel="quoteCancel" v-on:setLoading="setLoading" v-on:handleCallAPI="handleCallAPI"
                :discardDialog="discardDialog" />
            </card>
            <card>
              <b-row>
                <b-col :xl="windowWidth >= 1200 && windowWidth < 1300 ? 4 : 3" lg="4" md="5" class="p-1">
                  <span class="text">Settings Time: </span>
                  <el-dropdown trigger="click" @command="handleCommand">
                    <el-button>
                      {{ activeName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="Year">Year</el-dropdown-item>
                      <el-dropdown-item command="Month">Month</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </b-col>
                <b-col :xl="windowWidth >= 1200 && windowWidth < 1300 ? 5 : 4" lg="5" md="6" class="p-1">
                  <span class="text">Calendar: </span>
                  <el-date-picker class="mobile-calendar" v-if="activeIndex === 0" v-model="year" type="year"
                    align="right" :clearable="false" :picker-options="pickerOptions" default-time="2021"
                    placeholder="Pick a year">
                  </el-date-picker>
                  <el-date-picker class="mobile-calendar" v-if="activeIndex === 1" v-model="month" type="month"
                    align="right" :clearable="false" :picker-options="pickerOptions" placeholder="Pick a month">
                  </el-date-picker>
                </b-col>
                <b-col :xl="2" lg="2" md="12" class="layout p-1">
                  <button @click="getData()" class="btn base-button btn-danger submit">Submit</button>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { Button, DatePicker, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { BasePagination } from "@/components";
import { Table, TableColumn, Select, Option, Notification } from "element-ui";
import { appFunctions } from "../../helpers/appFunctions";
import { GlobalStore } from "../../globalStore";
import { formattingMethods } from "../../helpers/formatting";
import DialogConfirm from "../components/DialogConfirm.vue";
import DialogResume from "../components/DialogResume.vue";
import DialogDiscard from "../components/DialogDiscard.vue";
import CancelPolicies from "../CancelPolicies.vue";
import Spinner from "../dashboard/Spinner.vue";

import moment from 'moment';
export default {
  props: {
  },
  name: "menu-bar",
  data() {
    return {
      renewalData: [],
      windowWidth: window.innerWidth,
      month: "",
      year: "",
      isLoading: false,
      loading: false,
      activeName: "Month",
      activeIndex: 1,
      pickerOptions: {
        disabledDate(time) {
          return !moment(time.getTime()).isBetween('2022-01-01', Date.now());
        },
      },
      title: '',
      quoteCancel: null,
      isCancel: false,
      message: '',
      discardDialog: false,
      titleDigLog: '',
      selectedRow: null,
      cancelConfirmModal: false,
      centerDialogVisible: false,
    };
  },
  mounted() {
    this.month = Date.now()
    this.year = Date.now()
    this.fetchData();
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    async goStepCancel() {
      this.isCancel = false
      this.cancelConfirmModal = false
      this.centerDialogVisible = false
      this.discardDialog = false
      //await this.getCustomerLCPolicies(true)
    },
    getRowKeys(row) {
      return row._id
    },
    setLoading(val) {
      this.isLoading = val
    },
    myEventHandler(e) {
      this.windowWidth = window.innerWidth
    },
    modelPopupCancel() {
      this.cancelConfirmModal = false
    },
    modelPopupResume() {
      this.centerDialogVisible = false
    },
    modelPopupDiscard() {
      this.discardDialog = false
    },
    handleContinuePolicies() {
      this.quoteId = this.quoteCancel.quoteId
      this.$router.push("/lightCommercial/policies?qg=" + this.quoteId);
      this.isCancel = true
    },
    async handleCallAPI(method) {
      this.isLoading = true
      const policyId = this.selectedRow.Ubind_quote_details_obj.policyId;
      await method(policyId)
        .then(resp => {
          this.quoteId = resp.quoteId
          this.isCancel = true;
          this.$router.push("/lightCommercial/policies?qg=" + this.quoteId);
          this.isLoading = false
        })
        .catch(err => {
          if (!err.response.data.data) {
            Notification.error({
              title: 'Error',
              message: 'You are missing the required permission!'
            });
          } else {
            this.quoteCancel = err.response.data.data
            this.cancelConfirmModal = false
            this.message = err.response.data.detail
            if (err.response.data.code.includes("exists.when")) {
              this.discardDialog = true
            } else {
              this.centerDialogVisible = true
            }
          }
        });
      this.isLoading = false
    },
    getData() {
      let year = null
      let month = null
      if (this.activeIndex === 0) {
        year = moment(this.year).year()
        month = 1 + moment(this.year).month()
      } else {
        year = moment(this.month).year()
        month = 1 + moment(this.month).month()
      }
      this.$emit('getDataChart', year, month, this.activeIndex, this.month)
    },
    handleCommand(command) {
      this.activeName = command
      const index = command === 'Year' ? 0 : 1
      this.activeIndex = index
    },
    formatPremium(row) {
      const totalPayable =
        row.Customer_policy_insurance_total_payable &&
        row.Customer_policy_insurance_total_payable.toString().replace("$", "");
      return "$" + formattingMethods.numberWithCommas(totalPayable);
    },
    formatDateWithSlash(dateVal) {
      if (dateVal) {
        return dateVal.replaceAll('/', '-')
      } else {
        return "N/A"
      }
    },
    formatDateTime(dateVal) {
      return formattingMethods.formatDateTime(dateVal);
    },
    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
    },
    handlePolicyModals(row, type) {
      const titles = {
        adjustment: `Are you sure you want to make \n an adjustment to policy ${row.policy_number}`,
        cancellation: `Are you sure you want to initiate \n cancellation of policy ${row.policy_number}?`,
        renew: `Are you sure you want to renew \n policy ${row.policy_number}?`
      }
      this.title = type
      this.titleDigLog = titles[type]
      this.selectedRow = null
      this.cancelConfirmModal = true
      this.selectedRow = row;
    },
    fetchData() {
      this.loading = true;
      appFunctions
        .crud_customerLCPolicy("listRenewalsByRealmId", {
          Realm_user_id: GlobalStore.brokerObj.Realm_user_id,
          type: "policy"
        })
        .then(resp => {

          this.loading = false;

          console.log(resp);
          var tdata = resp.data.data;



          tdata.forEach(q => {

            // Formatting number values.
            if (q.Ubind_quote_details_obj) {
              q.policy_number = q.Ubind_quote_details_obj.policyNumber || "";
              q.last_modified = q.Ubind_quote_last_updated_date;
            }

            q.Broker_Name = q.createdByName
            //q.isDisableCancel = q.Ubind_status === 'complete' ? false : true
            //q.isDisableAdjust = q.isDisableCancel
            //q.isDisableRenew = this.haveRenewButtonDisable(q)
            q.Ubind_status = this.capitalizeFirstLetter(q.Ubind_status);


            //const policyEndDate =  q.Ubind_quote_details_obj.formDataFormatted.policyEndDate ||
            //  q.Ubind_quote_details_obj.formData.policyEndDate;
            // const convertedPolicyEndDate = moment(convertDateToHyphen(policyEndDate));

            // // daysUntilExpired is negative value if current date is before policyEndDate
            // const daysUntilExpired = moment().diff(convertedPolicyEndDate, "days");

            //console.log(policyEndDate);
          });

          tdata.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              new Date(b.Ubind_quote_created_date) -
              new Date(a.Ubind_quote_created_date)
            );
          });

          this.renewalData = tdata;

          // this.initFuse([
          //   "Customer_insured_name",
          //   "Quote_Ref_No",
          //   "Status",
          //   "Broker_Name",
          //   "Broker_Org_Name"
          // ]);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Button.name]: Button,
    DialogConfirm,
    CancelPolicies,
    DialogResume,
    DialogDiscard,
    Spinner
  },
};
</script>

<style scoped>
.text-danger {
  font-size: 2.5em;
}

.border-button {
  border-radius: 20px !important;
  border: 1px solid white;
  background: #f7fafc !important;
}

.active {
  border: 1px solid #ff4438;
}

.font-color {
  color: black;
}

.submit {
  border-radius: 20px;
}

.title {
  text-align: center;
  margin-right: 10px;
  font-weight: 600;
  margin-top: 2px;
}

@media only screen and (min-width: 340px) and (max-width: 1240px) {
  .layout {
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1459px) {
  .layout {
    text-align: center;
  }
}

@media only screen and (min-width: 414px) and (max-width: 768px) {
  .mobile-calendar {
    margin-left: 30px;
  }

  .mobile-status {
    margin-left: 30px;
  }

  .text {
    font-size: 15px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 414px) {
  .mobile-calendar {
    margin-left: 28px;
  }

  .mobile-status {
    margin-left: 50px;
  }

  .text {
    font-size: 15px;
  }
}</style>
